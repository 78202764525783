export const Governates = [
  {
    governate: "KSA",
    title: "السعودية",
    cities: [
      { title: "عقيق", value: "Aqiq" },
      { title: "عطالله", value: "Atawlah" },
      { title: "الباحة", value: "Baha" },
      { title: "بلجرشي", value: "Baljurashi" },
      { title: "المندق", value: "Mandaq" },
      { title: "المظيلف", value: "Mudhaylif" },
      { title: "المخواة", value: "Mukhwah" },
      { title: "قلوة", value: "Qilwah" },
      { title: "القنفذة", value: "Qunfudhah" },
      { title: "الجوف", value: "Al Jouf" },
      { title: "دومة الجندل", value: "Dawmat Al Jandal" },
      { title: "سكاكا", value: "Skakah" },
      { title: "باشوت", value: "Bashayer" },
      { title: "بللسمر", value: "Bellasmar" },
      { title: "النماص", value: "Namas" },
      { title: "سبت العلايا", value: "Sapt Al Alaya" },
      { title: "تنومة", value: "Tanumah" },
      { title: "عين دار", value: "Ain Dar" },
      { title: "عنك", value: "Anak" },
      { title: "جسر البحرين", value: "Bahrain Causeway" },
      { title: "بقيق", value: "Buqaiq" },
      { title: "الدمام", value: "Dammam" },
      { title: "مطار الدمام", value: "Dammam Airport" },
      { title: "الظهران", value: "Dhahran" },
      { title: "الجبيل", value: "Jubail" },
      { title: "الخفجي", value: "Khafji" },
      { title: "الخبر", value: "Khubar" },
      { title: "منيفة", value: "Muneefa" },
      { title: "النعيرية", value: "Nairiyah" },
      { title: "قرية العليا", value: "Qarya Al Uliya" },
      { title: "القطيف", value: "Qatif" },
      { title: "رحيمة", value: "Rahima" },
      { title: "رأس تنورة", value: "Ras Tannurah" },
      { title: "صفوى", value: "Safwa" },
      { title: "سيرة", value: "Saira" },
      { title: "سيهات", value: "Sayhat" },
      { title: "شدقم", value: "Shedgum" },
      { title: "تناجيب", value: "Tanajib" },
      { title: "تاروت (دارين)", value: "Tarut (Darin)" },
      { title: "الثقبة", value: "Thqbah" },
      { title: "العضيلية", value: "Udhayliyah" },
      { title: "عثمانية", value: "Uthmaniyah" },
      { title: "نجران", value: "Najran" },
      { title: "شرورة", value: "Sharourah" },
      { title: "وادي الدواسر", value: "Wadi Al-Dawasir" },
      { title: "البدايع", value: "Badaya" },
      { title: "البكيرية", value: "Bukayriyah" },
      { title: "بريدة", value: "Buraydah" },
      { title: "الدخنة", value: "Dukhnah" },
      { title: "الخبراء", value: "Khabra" },
      { title: "المذنب", value: "Midhnab" },
      { title: "النبهانية", value: "Nabhaniah" },
      { title: "نفي", value: "Nifi" },
      { title: "مطار القصيم", value: "Qaseem Airport" },
      { title: "رفيعة الغمش", value: "Rafayaa Al Gimsh" },
      { title: "الرس", value: "Rass" },
      { title: "رياض الخبراء", value: "Riyadh Al Khabra" },
      { title: "ساجر", value: "Sajir" },
      { title: "عنيزة", value: "Unayzah" },
      { title: "عقلة الصقور", value: "Uqlat As Suqur" },
      { title: "عيون الجواء", value: "Uyun Al Jiwa" },
      { title: "أبو عريش", value: "Abu Arish" },
      { title: "أحد المسارحة", value: "Ahad Al Masarhah" },
      { title: "الدائر", value: "Al Dayer" },
      { title: "الطوال", value: "At Tuwal" },
      { title: "بني مالك", value: "Bani Malek" },
      { title: "بيش", value: "Baysh" },
      { title: "الدرب", value: "Darb" },
      { title: "ضمد", value: "Dhamad" },
      { title: "فرسان", value: "Farasan" },
      { title: "جازان", value: "Jazan" },
      { title: "صبياء", value: "Sabya" },
      { title: "صامطة", value: "Samtah" },
      { title: "الشقيق", value: "Shuqayq" },
      { title: "حائل", value: "Hail" },
      { title: "سائرة", value: "Sayirah" },
      { title: "الروقي", value: "Al Ruqi" },
      { title: "حفر الباطن", value: "Hafar Al Baten" },
      { title: "مدينة الملك خالد", value: "King Khalid City" },
      { title: "القيصومة", value: "Qaysumah" },
      { title: "رفحاء", value: "Rafha" },
      { title: "سعرار", value: "Sarrar" },
      { title: "الأحساء", value: "Al Ahsa" },
      { title: "العيون", value: "Al Ayun" },
      { title: "الجفر", value: "Al Jafr" },
      { title: "بطحاء", value: "Batha" },
      { title: "الهفوف", value: "Hufuf" },
      { title: "المبرز", value: "Mubarraz" },
      { title: "سلوى", value: "Salwa" },
      { title: "بدر", value: "Badr" },
      { title: "بحرة", value: "Bahrah" },
      { title: "جدة", value: "Jeddah" },
      { title: "مطار جدة", value: "Jeddah Airport" },
      { title: "كامل", value: "Kamil" },
      { title: "خليص", value: "Khulais" },
      { title: "الليث", value: "Lith" },
      { title: "مستورة", value: "Masturah" },
      { title: "رابغ", value: "Rabigh" },
      { title: "الشعيبة", value: "Shaibah" },
      { title: "ثول", value: "Thuwal" },
      { title: "أبها", value: "Abha" },
      { title: "أحد رفيدة", value: "Ahad Rafidah" },
      { title: "بارق", value: "Bariq" },
      { title: "بيشة", value: "Bishah" },
      { title: "ظهران الجنوب", value: "Dhahran Al Janoub" },
      { title: "جاش", value: "Jash" },
      { title: "خميس مشيط", value: "Khamis Mushayt" },
      { title: "مجاردة", value: "Majardah" },
      { title: "محايل", value: "Muhayil" },
      { title: "نقيع", value: "Nakeea" },
      { title: "رجال ألمع", value: "Rijal Almaa" },
      { title: "سراة عبيدة", value: "Sarat Abida" },
      { title: "طريب", value: "Tarib" },
      { title: "تثليث", value: "Tathlith" },
      { title: "وادي بن هشبل", value: "Wadi Bin Hashbal" },
      { title: "جموم", value: "Jamoum" },
      { title: "مكة", value: "Makkah" },
      { title: "حنكية", value: "Hanakiyah" },
      { title: "خيبر", value: "Khayber" },
      { title: "المدينة المنورة", value: "Madinah" },
      { title: "مهد الذهب", value: "Mahd Ad Dhahab" },
      { title: "العلا", value: "Ula" },
      { title: "عفيف", value: "Afif" },
      { title: "الارتوازية", value: "Artawiyah" },
      { title: "بجادية", value: "Bijadiyah" },
      { title: "الدودامي", value: "Duwadimi" },
      { title: "غاط", value: "Ghat" },
      { title: "حوطة سدير", value: "Hawtat Sudayr" },
      { title: "المجمعة", value: "Majmaah" },
      { title: "شقراء", value: "Shaqra" },
      { title: "الزلفي", value: "Zulfi" },
      { title: "عرعر", value: "Arar" },
      { title: "جديدة عرعر", value: "Jadidah Arar" },
      { title: "الأفلاج (ليلى)", value: "Al Aflaj (Layla)" },
      { title: "ضرما", value: "Dhurma" },
      { title: "دلم", value: "Dilam" },
      { title: "الدرعية", value: "Diriyah" },
      { title: "حوطة بني تميم", value: "Hawtat Bani Tamim" },
      { title: "الحائر", value: "Hayer" },
      { title: "حريملاء", value: "Huraymila" },
      { title: "الخرج", value: "Kharj" },
      { title: "مزاحمية", value: "Muzahmiyah" },
      { title: "قويعية", value: "Quwayiyah" },
      { title: "رنية", value: "Ranyah" },
      { title: "الرياض", value: "Riyadh" },
      { title: "مطار الرياض", value: "Riyadh Airport" },
      { title: "رماح", value: "Rumah" },
      { title: "رويدة", value: "Ruwaidah" },
      { title: "ضرماء", value: "Dhalim" },
      { title: "الخرمة", value: "Khurmah" },
      { title: "المويه", value: "Muwayh" },
      { title: "رنية", value: "Ranyah" },
      { title: "السيّل الكبير", value: "Sayl Al Kabir" },
      { title: "الطائف", value: "Taif" },
      { title: "تربة", value: "Turbah" },
      { title: "تربة (مكة)", value: "Turbah (Makkah)" },
      { title: "بد", value: "Bad" },
      { title: "ضباء", value: "Dhuba" },
      { title: "حالت عمار", value: "Halit Ammar" },
      { title: "حقل", value: "Haql" },
      { title: "تبوك", value: "Tabuk" },
      { title: "تيماء", value: "Taima" },
      { title: "حديثة", value: "Haditha" },
      { title: "القريات", value: "Qurayyat" },
      { title: "طبرجل", value: "Tabarjal" },
      { title: "طريف", value: "Turayf" },
      { title: "خماسين", value: "Khamasin" },
      { title: "السليل", value: "Sulayyil" },
      { title: "بدر حنين", value: "Badar Hunain" },
      { title: "أملج", value: "Ummlujj" },
      { title: "الوجه", value: "Wajh" },
      { title: "ينبع", value: "Yanbu" },
      { title: "القصيم", value: "Qaseem" },
    ],
  },
  {
    governate: "UAE",
    title: "الامارات",
    cities: [
      { title: "العين", value: "Al Ain" },
      { title: "أبو ظبي", value: "Abu Dhabi" },
      { title: "دبي", value: "Dubai" },
      { title: "الفجيرة", value: "Fujairah" },
      { title: "عجمان", value: "Ajman" },
      { title: "أم القيوين", value: "Umm Al Quwain" },
      { title: "رأس الخيمة", value: "Ras al Khaymah" },
      { title: "الشارقة", value: "Sharjah" },
    ],
  },
  {
    governate: "Bahrain",
    title: "البحرين",
    cities: [
      { title: "المنامة", value: "Manama" },
      { title: "البديع", value: "Budaiya" },
      { title: "البسيتين", value: "BUSAITEEN" },
      { title: "الحد", value: "Hidd" },
      { title: "مدينة حمد", value: "HAMADTOWN" },
      { title: "هملة", value: "HAMALA" },
      { title: "مدينة عيسى", value: "ISATOWN" },
      { title: "الجفير", value: "JUFFAIR" },
      { title: "المحرق", value: "Muharraq" },
      { title: "ميناء سلمان", value: "MINA SALMAN" },
      { title: "الرفاع", value: "RIFFA" },
      { title: "سار", value: "SAAR" },
      { title: "السيف", value: "SEEF" },
      { title: "سترة", value: "SITRA" },
      { title: "توبلي", value: "TUBLI" },
    ],
  },
  {
    governate: "Egypt",
    title: "مصر",
    cities: [
      { title: "الإسكندرية", value: "Alexandria" },
      { title: "أسيوط", value: "Assiout" },
      { title: "أسوان", value: "Aswan" },
      { title: "البحيرة", value: "Behira" },
      { title: "بني سويف", value: "Beni Souif" },
      { title: "القاهرة", value: "Cairo" },
      { title: "القاهرة الكبرى", value: "Cairo Sub." },
      { title: "الدقهلية", value: "Dakhlia" },
      { title: "دمياط", value: "Demiatta" },
      { title: "الفيوم", value: "Fayoum" },
      { title: "الغربية", value: "Gharbia" },
      { title: "الإسماعيلية", value: "Ismailia" },
      { title: "كفر الشيخ", value: "Kafr Elshiekh" },
      { title: "الأقصر", value: "Luxor" },
      { title: "المنيا", value: "Minia" },
      { title: "المنوفية", value: "Menoufia" },
      { title: "مرسى مطروح", value: "Marsa Matrouh" },
      { title: "شمال سيناء", value: "North Sinai" },
      { title: "الوادي الجديد", value: "New Valley" },
      { title: "بورسعيد", value: "Port Said" },
      { title: "القليوبية", value: "Qalioubia" },
      { title: "قنا", value: "Qena" },
      { title: "البحر الأحمر", value: "Red Sea" },
      { title: "سوهاج", value: "Sohag" },
      { title: "الشرقية", value: "Sharkia" },
      { title: "جنوب سيناء", value: "South Sinai" },
      { title: "السويس", value: "Suez" },
    ],
  },
  {
    governate: "Oman",
    title: "عمان",
    cities: [
      { title: "آدم", value: "ADAM" },
      { title: "الخوض", value: "AL KHOUDH" },
      { title: "الخوير", value: "AL KHUWAIR" },
      { title: "الكامل", value: "AL KAMIL" },
      { title: "العامرات", value: "AMERAT" },
      { title: "الأنصب", value: "AL ANSAB" },
      { title: "الوافي", value: "AWABI" },
      { title: "العذيبة", value: "AZAIBA" },
      { title: "بركاء", value: "BARKA" },
      { title: "بداية", value: "BIDAYA" },
      { title: "بدية", value: "BEDEYA" },
      { title: "بهلاء", value: "BAHLA" },
      { title: "بدبد", value: "BIDBID" },
      { title: "بوشر", value: "BOUSHER" },
      { title: "البريمي", value: "BURAIMI" },
      { title: "دارسيت", value: "DARSAIT" },
      { title: "ضنك", value: "DHANK" },
      { title: "الدقم", value: "DUQUM" },
      { title: "فنجاء", value: "FANJA" },
      { title: "فلاج", value: "FALAJ" },
      { title: "غلا", value: "GHALA" },
      { title: "الغبرة", value: "GHOBRA" },
      { title: "هيماء", value: "HAIMA" },
      { title: "حلبي", value: "HALBAN" },
      { title: "حيل", value: "HAIL" },
      { title: "الحمراء", value: "HAMRA" },
      { title: "الحمرية", value: "HAMRIYA" },
      { title: "عبري", value: "IBRI" },
      { title: "إبراء", value: "IBRA" },
      { title: "إزكي", value: "IZKI" },
      { title: "جعلان بني حسن", value: "JALAN BU HASSAN" },
      { title: "جفنين", value: "JIFNAIN" },
      { title: "جعلان", value: "JALAN" },
      { title: "كامل", value: "KAMIL" },
      { title: "خصب", value: "KHASAB" },
      { title: "الخضراء", value: "KHADRA" },
      { title: "الخابورة", value: "KHABOURAH" },
      { title: "لوى", value: "LIWA" },
      { title: "لزوغ", value: "LIZOUGH" },
      { title: "المعبيلة", value: "MABELLA" },
      { title: "محوت", value: "MAHOOT" },
      { title: "مدينة الإعلام", value: "MADINAT AL ILLAM" },
      { title: "مصيرة", value: "MASHEERA" },
      { title: "الموالح", value: "MAWELLAH" },
      { title: "مسقط", value: "MUSCAT" },
      { title: "منتراب", value: "MINTRIB" },
      { title: "مصفاة", value: "MISFAH" },
      { title: "منح", value: "MANAH" },
      { title: "مدينة السلطان قابوس", value: "MADINAT SULTAN QABOOS" },
      { title: "مرباط", value: "MARBATH" },
      { title: "المضيبي", value: "MUDHAIBI" },
      { title: "الملاحة", value: "MULLADHA" },
      { title: "المصنعة", value: "MUSSNAH" },
      { title: "مطرح", value: "MUTTRAH" },
      { title: "نخل", value: "NAKHAL" },
      { title: "نزوى", value: "NIZWA" },
      { title: "القُرم", value: "QURUM" },
      { title: "الرستاق", value: "RUSTAQ" },
      { title: "رميس", value: "RUMAIS" },
      { title: "رسيل", value: "RUSAYL" },
      { title: "روي", value: "RUWI" },
      { title: "سماد الشان", value: "SAMAD AL SHAN" },
      { title: "السيب", value: "SEEB" },
      { title: "شناص", value: "SHINAS" },
      { title: "صحم", value: "SAHAM" },
      { title: "سداب", value: "SIDAB" },
      { title: "صلالة", value: "SALALAH" },
      { title: "سمائل", value: "SUMAIL" },
      { title: "سناو", value: "SINAW" },
      { title: "صحار", value: "SOHAR" },
      { title: "صور", value: "SUR" },
      { title: "السويق", value: "SUWAIQ" },
      { title: "ثرمد", value: "THARMAD" },
      { title: "ثمريت", value: "THUMRAIT" },
      { title: "وادي كبير", value: "WADI KABIR" },
      { title: "وادي عدي", value: "WADI ADAI" },
      { title: "وادي حطاط", value: "WADI HATAT" },
      { title: "الوطية", value: "WATTAYA" },
      { title: "ودام", value: "WUDAM" },
      { title: "ينقل", value: "YANQUL" },
    ],
  },
  {
    governate: "Kuwait",
    title: "الكويت",
    cities: [
      { title: "الأحمدي", value: "AL AHMADI" },
      { title: "العاصمة", value: "AL AASMAH" },
      { title: "الفروانية", value: "FARWANIYAH" },
      { title: "حولي", value: "HAWALLY" },
      { title: "الجهراء", value: "AL JAHRA" },
      { title: "مبارك الكبير", value: "MUBARAK AL KABEER" },
    ],
  },
];
