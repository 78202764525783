import React, { Suspense } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import NavBar from "./components/navBar/navBar";
import Footer from "./components/footer/footer";
import camel from "./assets/camel.gif";
import LiveAuction from "./pages/liveAuction/liveAuction";
import SignIn from "./pages/signIn/signIn";
import SignUp from "./pages/signup/signup";
import OTP from "./pages/otp/otp";
import Auctions from "./pages/auctions/auctions";
import AboutUs from "./pages/aboutUs/aboutUs";
import DashBoard from "./pages/dashboard/dashboard";
import LiveAuctionRequest from "./pages/liveAuctionRequest/liveAuctionRequest";
import Checkout from "./pages/checkout/checkout";
import ConfirmOrder from "./pages/confirmOrder/confirmOrder";

function MainContent() {
  const location = useLocation();

  // Check if the current route is the live auction page
  const isLiveAuction = location.pathname.startsWith("/liveAuction");
  const isDashBoard = location.pathname.startsWith("/dashboard");
  return (
    <>
      {/* Conditionally render NavBar if not on the live auction page */}
      {!isLiveAuction && !isDashBoard && <NavBar />}

      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/otp/:id" element={<OTP />} />
        <Route path="/auctions" element={<Auctions />} />
        <Route path="/liveAuction/:id" element={<LiveAuction />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/requestLiveAuction" element={<LiveAuctionRequest />} />
        <Route path="/dashboard" element={<DashBoard />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/confirmOrder/:orderId" element={<ConfirmOrder />} />
      </Routes>

      {/* Conditionally render Footer if not on the live auction page */}
      {!isLiveAuction && !isDashBoard && <Footer />}
    </>
  );
}

export default MainContent;
