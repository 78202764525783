import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./checkout.css";
import { CalculateWinnings } from "../../utils/calculateWinnings";
import { UserContext } from "../../context/userContext";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import axios from "axios";
import { Governates } from "../../utils/governates";
import Select from "react-select";

const moment = require("moment");

const Checkout = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [shipping, setShipping] = useState(0);
  const navigate = useNavigate();
  const [orderId, setOrderId] = useState(0);
  const [showCreditPay, setShowCreditPay] = useState(false);
  const [total, setTotal] = useState(CalculateWinnings(data));
  const { user } = useContext(UserContext);

  const [order, setOrder] = useState({
    user_id: user.id,
    date: moment(new Date()).format("YYYY-MM-DD"),
    cName: "",
    cCountry: "KSA",
    cCity: "ULA",
    cMobile: "",
    cAddress: "",
    PCs: data.length,
    codAmt: 0,
    weight: "1",
    itemDesc: data.map((item) => item.product).join(", "),
    total: CalculateWinnings(data),
    auction_id: data[0].auction_id
  });

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const handleCountryChange = (selectedOption) => {
    setOrder({
      ...order,
      cCountry: selectedOption.value,
    });
    setSelectedCountry(selectedOption);
    setSelectedCity(null); // Reset city selection when country changes
  };

  const handleCityChange = (event, newValue) => {
    setOrder({
      ...order,
      cCity: newValue?.value,
    });
    setSelectedCity(newValue);
  };

  const countryOptions = Governates.map((country) => ({
    label: country.title,
    value: country.governate,
    cities: country.cities, // Include cities in the country option
  }));

  const cityOptions =
    selectedCountry && selectedCountry.cities
      ? selectedCountry.cities.map((city) => ({
          label: city.title,
          value: city.value,
        }))
      : []; // Ensure cities are only mapped if selectedCountry has cities

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "order/add-order",
        order
      );
      if (order.codAmt === 0) {
        setOrderId(response.data.data.id);
        setShowCreditPay(true);
      } else {
        toast.success("تمت العملية بنجاح");
        navigate("/auctions");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (showCreditPay && window.Moyasar) {
      const baseUrl = window.location.origin;
      // Initialize Moyasar when the component is mounted
      window.Moyasar.init({
        element: ".mysr-form", // Bind the form to this class name
        amount: total * 100, // Amount in the smallest currency unit
        currency: "SAR", // Currency (SAR in this case)
        description: data.map((item) => item.product).join(", "), // Description of the payment
        publishable_api_key: "pk_test_jsYhiLnDNqysY81Zmq1kr5BZkJcwnjb7dxpzhLov", // Your public key
        callback_url: `${baseUrl}/confirmOrder/${orderId}`,
        methods: ["creditcard"], // Payment methods allowed
        onCompleted: (payment) => {
          console.log("Payment successful:", payment);

          // Handle the successful payment response
          // For example, update the state, inform the user, or navigate
          alert("Payment completed successfully!");
          navigate("/success");
          // Navigate within the app if you're using React Router
          // For example: navigate("/success"); using `useNavigate` from react-router-dom
        },
        onFailed: (error) => {
          console.error("Payment failed:", error);

          // Inform the user about the payment failure
          alert("Payment failed. Please try again.");
        },
      });
    } else {
      console.log("not here");
    }
  }, [navigate, data, orderId, total, showCreditPay]);

  console.log(order)
  return (
    <div className="checkOut-page">
      {showCreditPay ? (
        <div
          className="mysr-form"
          style={{ marginTop: "20%", height: "80vh" }}
        ></div>
      ) : (
        <div className="body">
          <div className="checkOut-content">
            <div className="receipt-details">
              <h3 className="receipt-details_text">تفاصيل الفاتورة</h3>

              <div>الاسم</div>
              <div>
                <input
                  className="checkOut-input"
                  style={{ width: "100%" }}
                  value={order.cName}
                  onChange={(e) =>
                    setOrder({
                      ...order,
                      cName: e.target.value,
                    })
                  }
                />
              </div>

              <div style={{ marginTop: "2rem" }}>الهاتف</div>

              <div style={{ display: "flex" }}>
                <input
                  className="checkOut-input"
                  style={{ width: "100%" }}
                  value={order.cMobile}
                  onChange={(e) =>
                    setOrder({
                      ...order,
                      cMobile: e.target.value,
                    })
                  }
                />
              </div>

              <div style={{ marginTop: "2rem" }}>الدولة</div>

              <div style={{ marginTop: "1rem" }}>
                <Select
                  options={countryOptions}
                  onChange={handleCountryChange}
                  placeholder=""
                />

                {selectedCountry && (
                  <div>
                    <div style={{ marginTop: "2rem" }}>المدينة</div>
                    <Autocomplete
                      options={cityOptions}
                      sx={{
                        marginTop: "1rem",
                      }}
                      getOptionLabel={(option) => option.label}
                      onChange={handleCityChange}
                      renderInput={(params) => (
                        <TextField {...params} label="" />
                      )}
                    />
                  </div>
                )}
              </div>

              <div style={{ marginTop: "2rem" }}>عنوان الشارع / الحى</div>

              <div style={{ display: "flex" }}>
                <input
                  className="checkOut-input"
                  style={{ width: "100%" }}
                  value={order.cAddress}
                  onChange={(e) =>
                    setOrder({
                      ...order,
                      cAddress: e.target.value,
                    })
                  }
                />
              </div>

              <div style={{ marginTop: "2rem" }}>ملاحظات الطلب (اختيارى)</div>

              <div style={{ display: "flex" }}>
                <textarea
                  className="checkOut-input"
                  style={{ width: "100%" }}
                  value={order.notes}
                  onChange={(e) =>
                    setOrder({
                      ...order,
                      notes: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="receipt">
              <h3 className="receipt_text">طلبك</h3>

              <div className="receiptDetails_content">
                <div className="receiptDetails_content_container">
                  <div className="receiptDetails_Text">المنتج</div>
                  <div className="receiptDetails_Text">المجموع</div>
                </div>

                {data
                  ? data.map((item) => (
                      <div className="receiptDetails_content_container marginTop">
                        <div>
                          <div>{item.product}</div>
                          {/* {Object.entries(item?.descriptions).map(
                          ([id, descriptions]) => (
                            <div>
                              {descriptions.title}: {descriptions.value}
                            </div>
                          )
                        )} */}
                        </div>
                        <div>
                          {String(item.price).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          )}
                        </div>
                      </div>
                    ))
                  : null}

                <div className="receiptDetails_content_container marginTop">
                  <div className="receiptDetails_Text">المجموع</div>
                  <div className="receiptDetails_Text">
                    {String(CalculateWinnings(data)).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}
                  </div>
                </div>

                <div className="receiptDetails_content_container marginTop">
                  <div className="receiptDetails_Text">الشحن</div>
                  <div className="receiptDetails_Text">{shipping}</div>
                </div>

                <div
                  className="receiptDetails_content_container marginTop"
                  style={{ border: "none" }}
                >
                  <div className="receiptDetails_Text">المجموع</div>
                  <div className="receiptDetails_Text">
                    {String(CalculateWinnings(data) + shipping).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    )}
                  </div>
                </div>

                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={checked}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setOrder({
                            ...order,
                            codAmt: 1,
                          });
                        } else {
                          setOrder({
                            ...order,
                            codAmt: 0,
                          });
                        }
                      }}
                      color="primary"
                    />
                  }
                  label="الدفع عند الاستلام"
                />
              </div>

              <div style={{ marginTop: "2rem" }}>
                <button
                  className={`primaryButton`}
                  onClick={handleClick}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <svg
                      className="w-5 h-5 mx-auto animate-spin text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.963 7.963 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  ) : (
                    "تأكيد الطلب"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checkout;
