import axios from "axios";
import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ConfirmOrder = () => {
  const { orderId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const isRequestSent = useRef(false);

  // Extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("message");
  const status = queryParams.get("status");
  const payment_id = queryParams.get("id");

  useEffect(() => {
    if (message === "APPROVED" && !isRequestSent.current) {
      isRequestSent.current = true;
      axios
        .post(process.env.REACT_APP_BASE_URL + "order/confirm-order", {
          order_id: +orderId,
          payment_status: status,
          payment_id: payment_id
        })
        .then(() => {
          toast.success("تمت العملية بنجاح");
          navigate("/auctions");
        })
        .catch((err) => {
          toast.error(err.message);
        });
    }
  }, [message, orderId, navigate]);

  return (
    <div
      style={{
        height: "80vh",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      برجاء الانتظار.....
    </div>
  );
};

export default ConfirmOrder;
