import React, { useContext, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/userContext";

import vectoryVector from "../../assets/vectoryVector.png";
import userProfileImg from "../../assets/BarakaLogo-preview.jpg";
import { io } from "socket.io-client";
import "./liveAuction.css";
import axios from "axios";
import { toast } from "react-toastify";
import AgoraRTC from "agora-rtc-sdk-ng";
import BidModal from "../../components/bidModal/bidModal";
import AwardingModal from "../../components/awardingModal/awardingModal";
import WinningModal from "../../components/winningModal/winningModal";
import LosingModal from "../../components/losingModal/losingModal";
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa6";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCameraRotate,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import ProductModal from "../../components/productModal/productModal";

const LiveAuction = () => {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const [auction, setAuction] = useState();
  const [comment, setComment] = useState("");
  const [socket, setSocket] = useState(null);
  const [views, setViews] = useState(0);
  const [lastBidNumber, setLastBidNumber] = useState(0);
  const [lastBidName, setLastBidName] = useState("");
  const [comments, setComments] = useState([]);
  const [showBidModal, setShowBidModal] = useState(false);
  const [showAwardingModal, setShowAwardingModal] = useState(false);
  const [visibleComments, setVisibleComments] = useState(10); // Initial number of visible comments
  const commentsRef = useRef(null); // Reference to comments container
  const [bid, setBid] = useState();
  const [isBroadcaster, setIsBroadcaster] = useState("");
  const localVideoRef = useRef(null);
  const [productDescription, setProductDescription] = useState("");
  const [minBidPrice, setMinBidPrice] = useState(0);
  const [bidPrice, setBidPrice] = useState(0);
  const [actualPrice, setActualPrice] = useState(0);
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [showLosingModal, setShowLosingModal] = useState(false);
  const [showProductModal, setShowProductModa] = useState(false);
  const [productDescriptionModal, setProductDescriptionModal] = useState({
    product: "",
    bidPrice: "",
    minBidPrice: "",
    actualPrice: "",
  });
  const [scrollAtBottom, setScrollAtBottom] = useState(true);
  const [rtc, setRtc] = useState({
    client: null,
    localVideoTrack: null,
    localAudioTrack: null,
  });
  const [isMuted, setIsMuted] = useState(false);
  const navigate = useNavigate();

  const [winningUser, setWinningUser] = useState(null);
  const [winningBid, setWinningBid] = useState(null);
  const [isUserPublished, setIsUserPublished] = useState(false);
  const [braodCasterLeft, setBroadCasterLeft] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const remoteAudioTrackRef = useRef(null);
  const remoteVideoTrackRef = useRef(null);
  const remoteClientRef = useRef(null);
  const [uid] = useState(Math.floor(Math.random() * 1000000));
  const hasInitialized = useRef(false);

  function handleComment(e) {
    e.preventDefault();
    socket.emit("comment", {
      auctionId: +id,
      userId: +user.id,
      comment: comment,
    });
    setComment("");
  }
  // Load more comments when scrolling to the top
  // Handle scroll event for loading older comments
  const handleScroll = () => {
    const commentsContainer = commentsRef.current;
    if (!commentsContainer) return;

    const top = commentsContainer.scrollTop;
    const atBottom =
      commentsContainer.scrollHeight - commentsContainer.scrollTop ===
      commentsContainer.clientHeight;

    // Load more comments if scrolled to the top
    if (top === 0 && visibleComments < comments.length) {
      setVisibleComments((prev) => Math.min(prev + 10, comments.length)); // Load 10 more comments
    }

    // Track if the user is scrolled to the bottom
    setScrollAtBottom(atBottom);
  };

  function handleBid(e) {
    e.preventDefault();

    if (+bid < +minBidPrice) {
      toast.error(`قيمة فتح باب المزاد ${+minBidPrice} ريال`);
      return;
    }

    if (+bid % +bidPrice !== 0) {
      toast.error(`يجب المزايدة بمضاعفات الرقم ${bidPrice} `);
      return;
    }

    if (+bid > +(lastBidNumber + 3 * +bidPrice) && +lastBidNumber !== 0) {
      toast.error("غير مسموح لك المزايدة بأكتر من 3 اضعاف ");
      return;
    }

    if (+bid > +actualPrice) {
      toast.error("قيمة المزاودة اعلى من حد السوم");
      return;
    }
    if (+bid <= +lastBidNumber) {
      toast.error("يجب المزايدة بمبلغ اعلى من اخر مزايدة");
      return;
    }
    socket.emit("placeBid", {
      auctionId: +id,
      userId: +user.id,
      amount: +bid,
    });
    setShowBidModal(false);
    setBid();
  }

  function handleShowBid() {
    setShowBidModal(true);
  }

  function handleShowAwarding() {
    setShowAwardingModal(true);
  }

  function handleAwarding(e) {
    e.preventDefault();
    socket.emit("awardingAuction", {
      auctionId: +id,
      userId: +user.id,
      product: productDescription,
    });
  }

  function handleChangeProduct(e) {
    e.preventDefault();
    socket.emit("changeCuurentProduct", {
      auctionId: +id,
      product: productDescriptionModal.product,
      minBidPrice: +productDescriptionModal.minBidPrice,
      bidPrice: +productDescriptionModal.bidPrice,
      actualPrice: +productDescriptionModal.actualPrice,
    });
    setProductDescriptionModal({
      product: "",
      bidPrice: "",
      minBidPrice: "",
      actualPrice: "",
    });
    setShowProductModa(false);
  }

  const handleSwitchCamera = async () => {
    try {
      if (rtc.localVideoTrack) {
        console.log(rtc.localVideoTrack);

        // Find the current facing mode (assuming default is "user")
        const currentFacingMode =
          rtc.localVideoTrack._constraints.facingMode || "user";
        const nextFacingMode =
          currentFacingMode === "user" ? "environment" : "user";

        // Stop and close the current video track
        await rtc.localVideoTrack.stop();
        await rtc.localVideoTrack.close();

        // Create a new video track with the opposite facing mode (front/rear camera)
        const newVideoTrack = await AgoraRTC.createCameraVideoTrack({
          facingMode: nextFacingMode, // Toggle between "user" and "environment"
        });

        // Update the rtc object with the new video track
        setRtc((prevRtc) => ({
          ...prevRtc,
          localVideoTrack: newVideoTrack,
        }));

        // Apply the CSS flip if using the front camera (user-facing)
        if (nextFacingMode === "user") {
          localVideoRef.current.style.transform = "scaleX(1)"; // Flip horizontally
        } else {
          localVideoRef.current.style.transform = "scaleX(-1)"; // No flip for rear camera
        }

        // Play the new video track in the video element
        newVideoTrack.play(localVideoRef.current);

        await rtc.client.unpublish(rtc.localVideoTrack); // Unpublish the old track
        await rtc.client.publish(newVideoTrack);
      } else {
        toast.error("No video track available to switch.");
      }
    } catch (error) {
      console.error("Failed to switch camera", error);
      toast.error(`Failed to switch camera: ${error.message}`);
    }
  };

  //  const handleSwitchCamera = async () => {
  //   try {
  //     if (!rtc.client || !rtc.localVideoTrack) {
  //       toast.error("Client or video track is not ready yet.");
  //       return;
  //     }

  //     const currentFacingMode =
  //       rtc.localVideoTrack._constraints.facingMode || "user";
  //     const nextFacingMode = currentFacingMode === "user" ? "environment" : "user";

  //     // Stop and close the current video track
  //     await rtc.localVideoTrack.stop();
  //     await rtc.localVideoTrack.close();

  //     // Create a new video track with the new facing mode
  //     const newVideoTrack = await AgoraRTC.createCameraVideoTrack({
  //       facingMode: nextFacingMode,
  //     });

  //     // Replace the local video track reference
  //     rtc.localVideoTrack = newVideoTrack;

  //     // Play the new video track
  //     newVideoTrack.play(localVideoRef.current);

  //     // Update the video orientation based on the facing mode
  //     if (nextFacingMode === "user") {
  //       localVideoRef.current.style.transform = "scaleX(1)";
  //     } else {
  //       localVideoRef.current.style.transform = "scaleX(-1)";
  //     }

  //     // Unpublish the old track and publish the new one without leaving the channel
  //     await rtc.client.unpublish();
  //     await rtc.client.publish(newVideoTrack);

  //     console.log("Camera switched successfully!");
  //   } catch (error) {
  //     console.error("Failed to switch camera", error);
  //     toast.error(`Failed to switch camera: ${error.message}`);
  //   }
  // };

  const handleMuteToggle = async () => {
    if (rtc.localAudioTrack) {
      if (isMuted) {
        await rtc.localAudioTrack.setEnabled(true); // Unmute
      } else {
        await rtc.localAudioTrack.setEnabled(false); // Mute
      }
      setIsMuted(!isMuted); // Toggle mute state
    } else {
    }
  };

  useEffect(() => {
    const commentsContainer = commentsRef.current;
    if (commentsContainer) {
      commentsContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (commentsContainer) {
        commentsContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleComments, comments.length]);

  // Scroll to bottom when a new comment is added if the user is already at the bottom
  useEffect(() => {
    if (scrollAtBottom && commentsRef.current) {
      commentsRef.current.scrollTo({
        top: commentsRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [comments, scrollAtBottom]);

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "auctions/get-auction", {
        params: {
          auction_id: +id,
        },
      })
      .then((response) => {
        setProductDescription(response.data.data.current_product);
        setMinBidPrice(response.data.data.minBidPrice);
        setBidPrice(response.data.data.bidPrice);
        setActualPrice(response.data.data.actualPrice);
        setAuction(response.data.data);
        setComments(response.data.data.Auction_comments);
        if (response.data.data.Auction_bids.length) {
          setLastBidName(response.data.data.Auction_bids[0].user.name);
          setLastBidNumber(response.data.data.Auction_bids[0].bid);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    const socketConnection = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"],
    });

    setSocket(socketConnection);

    socketConnection.emit("joinAuction", { auctionId: +id, userId: +user.id });
    // Listen for user count updates
    socketConnection.on("userCountUpdate", (data) => {
      setViews(+data.userCount);
    });

    socketConnection.on("newComment", (data) => {
      setComments((prevComments) => [...prevComments, data.newComment]);
    });

    socketConnection.on("newBid", (data) => {
      setLastBidNumber(data.newBid.bid);
      setLastBidName(data.newBid.user.name);
    });

    socketConnection.on("auction_change_product", (data) => {
      console.log(data);
      setProductDescription(data.product);
      setMinBidPrice(data.minBidPrice);
      setBidPrice(data.bidPrice);
      setActualPrice(data.actualPrice);
      // setAuction({
      //   ...auction,
      //   bidPrice: data.bidPrice,
      //   minBidPrice: data.minBidPrice,
      // });
    });

    socketConnection.on("broadCasterLeft", (data) => {
      if (isBroadcaster !== "true") {
        setBroadCasterLeft(true);
        setIsUserPublished(false);
      }
    });

    socketConnection.on("auctionEnded", (data) => {
      setComments(data.auction.Auction_comments);
      setLastBidName("");
      setLastBidNumber("");
      setProductDescription("");
      setBidPrice(0);
      setMinBidPrice(0);
      setActualPrice(0);
      setProductDescriptionModal({
        product: "",
        bidPrice: "",
        minBidPrice: "",
        actualPrice: "",
      });
      setShowAwardingModal(false);
      if (+user.id !== +data.auction.user_id) {
        setWinningUser(data.winningUser);
        setWinningBid(data.winningBid);
        setShowWinningModal(true);
      }
      // else if (
      //   +user.id !== +data.auction.user_id &&
      //   +data.winningUser.id !== +user.id
      // ) {
      //   console.log(isBroadcaster);
      //   console.log(data.winningUser.id);
      //   console.log(user.id);
      //   setShowLosingModal(true);
      // }
    });

    return async () => {
      if (socketConnection) {
        await socketConnection.emit("broadCasterLeave", {
          auctionId: +id,
          userId: +user.id,
        });
        socketConnection.disconnect();
        console.log("Socket disconnected");
      }
    };
  }, []);

  useEffect(() => {
    const initAgora = async (isBroadcaster) => {
      if (hasInitialized.current) return; // Prevent re-initialization

      try {
        hasInitialized.current = true;
        console.log("initialization of agora");
        AgoraRTC.onAutoplayFailed = () => {
          console.warn("Autoplay failed. Adding manual interaction.");
          setPopupVisible(true);
        };

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}agora/token`,
          {
            params: {
              channelName: `auction_${id}`,
              uid: uid,
              isPublisher: isBroadcaster,
            },
          }
        );

        const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

        await client.setClientRole(
          isBroadcaster === "true" ? "host" : "audience"
        );

        const response2 = await client.join(
          process.env.REACT_APP_AGORA_APP_ID,
          `auction_${id}`,
          response.data.data,
          uid
        );

        console.log(`response2: ${response2}`);

        remoteClientRef.current = client;

        if (isBroadcaster === "true") {
          const localVideoTrack = await AgoraRTC.createCameraVideoTrack();
          const localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();

          setIsUserPublished(true);
          setBroadCasterLeft(false);
          await client.publish([localVideoTrack, localAudioTrack]);
          localVideoTrack.play(localVideoRef.current);

          setRtc({ client, localVideoTrack, localAudioTrack });
        } else {
          client.on("user-published", async (remoteUser, mediaType) => {
            setIsUserPublished(true);
            setBroadCasterLeft(false);
            await client.subscribe(remoteUser, mediaType);

            if (mediaType === "video") {
              remoteVideoTrackRef.current = remoteUser.videoTrack;
              if (localVideoRef.current !== null) {
                remoteVideoTrackRef.current.play(localVideoRef.current);
              }
            }

            if (mediaType === "audio") {
              remoteAudioTrackRef.current = remoteUser.audioTrack;
              remoteAudioTrackRef.current.play();
            }
          });
        }

        // Mark as initialized
      } catch (error) {
        console.error("Failed to join Agora channel", error);
      }
    };

    if (auction && user) {
      const broadcaster = +user.id === +auction.user.id ? "true" : "false";
      setIsBroadcaster(broadcaster);
      initAgora(broadcaster);
    }

    return () => {
      if (isBroadcaster === "true") {
        if (rtc.client) {
          rtc.client.leave().then(() => {
            console.log("Broadcaster left the channel");
          });

          if (rtc.localVideoTrack) {
            rtc.localVideoTrack.stop();
            rtc.localVideoTrack.close();
          }

          if (rtc.localAudioTrack) {
            rtc.localAudioTrack.stop();
            rtc.localAudioTrack.close();
          }

          setRtc({
            client: null,
            localVideoTrack: null,
            localAudioTrack: null,
          });
        }
      } else {
        if (remoteAudioTrackRef.current) {
          remoteAudioTrackRef.current.stop();
        }

        if (remoteVideoTrackRef.current) {
          remoteVideoTrackRef.current.stop();
        }

        if (remoteClientRef.current) {
          remoteClientRef.current.leave().then(() => {
            console.log("Audience left the channel");
          });
        }
      }
    };
  }, [auction, id, rtc.client, uid, isBroadcaster, user]);

  return (
    <div>
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>تفعيل الصوت</h2>
            <p>
              تم حظر التشغيل التلقائي بواسطة متصفحك. يرجى النقر على الزر أدناه
              لتفعيل تشغيل الصوت.
            </p>
            <button
              onClick={() => {
                if (remoteAudioTrackRef.current) {
                  remoteAudioTrackRef.current.play();
                  setPopupVisible(false); // Close the popup
                } else {
                  alert("Audio track is not ready yet.");
                }
              }}
            >
              تفعيل الصوت
            </button>
          </div>
        </div>
      )}
      <div
        className="p-0 relative md:p-20"
        style={{
          backgroundColor: "black",
        }}
      >
        {showBidModal ? (
          <BidModal
            lastBid={minBidPrice}
            bid={bid}
            setBid={setBid}
            handleSubmit={handleBid}
            setShowModal={setShowBidModal}
          />
        ) : null}

        {showAwardingModal ? (
          <AwardingModal
            description={productDescription}
            setDescription={setProductDescription}
            handleSubmit={handleAwarding}
            setShowModal={setShowAwardingModal}
            winner={lastBidName}
          />
        ) : null}

        {showWinningModal ? (
          <WinningModal
            winningUser={winningUser}
            winningBid={winningBid}
            setShowModal={setShowWinningModal}
          />
        ) : null}

        {showProductModal ? (
          <ProductModal
            productModal={productDescriptionModal}
            setProductModal={setProductDescriptionModal}
            handleSubmit={handleChangeProduct}
            setShowModal={setShowProductModa}
          />
        ) : null}

        {showLosingModal ? (
          <LosingModal setShowModal={setShowLosingModal} />
        ) : null}

        {/* Main auction image */}
        <div className="relative">
          {/* <img
            src={liveAuctionImg}
            alt="Live Auction"
            className="w-full rounded-md"
          /> */}
          {isUserPublished ? (
            <div
              ref={localVideoRef}
              style={{ width: "100%", height: "90vh" }}
              className="rounded-md"
            ></div>
          ) : !braodCasterLeft ? (
            <div
              style={{
                width: "100%",
                height: "90vh",
                color: "white",
                backgroundColor: "black",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                سيبدأ المزاد بعد قليل ...
              </div>
            </div>
          ) : null}
          {braodCasterLeft && !isUserPublished ? (
            <div
              style={{
                width: "100%",
                height: "90vh",
                color: "white",
                backgroundColor: "black",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                تم خروج صاحب المزاد
              </div>
            </div>
          ) : null}

          {/* Exit icon in the top left corner of the image */}
          <div className="absolute top-5 left-5 cursor-pointer flex gap-2">
            {/* <img src={exitVector} alt="exit" /> */}
            <div
              onClick={() => {
                if (isBroadcaster === "true") {
                  if (rtc.client) {
                    rtc.client.leave().then(() => {
                      console.log("Left the channel");
                    });

                    if (rtc.localVideoTrack) {
                      rtc.localVideoTrack.stop();
                      rtc.localVideoTrack.close();
                    }

                    if (rtc.localAudioTrack) {
                      rtc.localAudioTrack.stop();
                      rtc.localAudioTrack.close();
                    }
                  }

                  navigate("/auctions");
                } else {
                  if (remoteVideoTrackRef.current) {
                    remoteVideoTrackRef.current.stop();
                  }
                  if (remoteAudioTrackRef) {
                    remoteAudioTrackRef.current.stop();
                  }
                  if (remoteClientRef.current) {
                    remoteClientRef.current.leave();
                  }
                  navigate("/auctions");
                }
              }}
            >
              <FontAwesomeIcon icon={faCircleXmark} size="2x" color="white" />
            </div>
            {/* <img
              src={walletVector}
              alt="wallet"
              className="ml-2"
               onClick={handleSwitchCamera}
            /> */}
            {isBroadcaster === "true" ? (
              <div onClick={() => handleSwitchCamera()}>
                <FontAwesomeIcon
                  icon={faCameraRotate}
                  size="2x"
                  color="white"
                />
              </div>
            ) : null}

            {/* <img src={profileVector} alt="profile" className="ml-2" /> */}
          </div>

          <div className="absolute top-10 right-5 flex-col mt-20">
            {" "}
            <div
              className="rounded-full p-2 flex items-center gap-4 mt-10"
              style={{
                background:
                  "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(89,89,89,1) 0%, rgba(28,175,205,1) 15%)",
              }}
            >
              {/* <svg
                class="h-8 w-8 text-red-500"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                style={{
                  color: "white",
                }}
              >
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <path d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2" />{" "}
                <path d="M12 3v3m0 12v3" />
              </svg> */}
              <div className="text-white font-bold">ر.س</div>

              <div className="text-white font-bold">
                {lastBidNumber.toLocaleString()}{" "}
              </div>
            </div>
          </div>

          <div
            className="absolute top-10 left-5 flex-col mt-10"
            onClick={() => {
              if (isBroadcaster === "true") {
                setShowProductModa(true);
              }
            }}
          >
            <div
              className="rounded-full p-2 flex gap-3"
              style={{
                background:
                  "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(89,89,89,1) 0%, rgba(28,175,205,1) 15%)",
              }}
            >
              {/* <img src={productVector} alt="product" /> */}
              <div className="text-white text-bold">
                وصف السلعة: {productDescription}
              </div>
            </div>
          </div>

          <div
            className="absolute top-10 left-5 flex-col mt-20"
            style={{ marginTop: "5.5rem" }}
            onClick={() => {
              if (isBroadcaster === "true") {
                setShowProductModa(true);
              }
            }}
          >
            <div
              className="rounded-full p-2 flex gap-3"
              style={{
                background:
                  "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(89,89,89,1) 0%, rgba(28,175,205,1) 15%)",
              }}
            >
              {/* <img src={productVector} alt="product" /> */}
              <div className="text-white text-bold">
                فتح الباب: {minBidPrice.toLocaleString()} ر.س
              </div>
            </div>
          </div>

          <div
            className="absolute top-10 left-5 flex-col mt-20"
            style={{
              marginTop: "8.5rem",
            }}
            onClick={() => {
              if (isBroadcaster === "true") {
                setShowProductModa(true);
              }
            }}
          >
            <div
              className="rounded-full p-2 flex gap-3"
              style={{
                background:
                  "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(89,89,89,1) 0%, rgba(28,175,205,1) 15%)",
              }}
            >
              {/* <img src={productVector} alt="product" /> */}
              <div className="text-white text-bold">
                فرق السوم: {bidPrice.toLocaleString()} ر.س
              </div>
            </div>
          </div>
          {isBroadcaster === "true" ? (
            <div
              className="absolute top-10 left-5 flex-col mt-20"
              style={{
                marginTop: "11.5rem",
              }}
              onClick={() => {
                if (isBroadcaster === "true") {
                  setShowProductModa(true);
                }
              }}
            >
              <div
                className="rounded-full p-2 flex gap-3"
                style={{
                  background:
                    "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(89,89,89,1) 0%, rgba(28,175,205,1) 15%)",
                }}
              >
                {/* <img src={productVector} alt="product" /> */}
                <div className="text-white text-bold">
                  حد السوم: {actualPrice.toLocaleString()} ر.س
                </div>
              </div>
            </div>
          ) : null}

          {/* Auction Creator Avatar and Name in the top right corner of the image */}
          <div className="absolute top-5 right-5 flex items-center">
            <div dir="rtl" className="flex items-center gap-4">
              <img
                className="w-14 h-14 rounded-full"
                style={{
                  width: "50px",
                  height: "50px",
                }}
                src={userProfileImg}
                alt="User Profile"
              />
              <div>
                <div className="font-bold text-white dark:text-white">
                  {auction ? (auction.user ? auction.user.name : "") : ""}
                </div>
                <div className="text-sm text-white dark:text-gray-400">
                  {views - 1} مشاهد
                </div>
              </div>
            </div>
          </div>

          <div className="absolute top-12 right-5 mt-12">
            <div
              className="rounded-full p-2 flex gap-2"
              style={{
                background:
                  "linear-gradient(90deg, #FDF9DB66 40%, #FBB650 100%)",
              }}
            >
              <div className="font-semibold text-white">{lastBidName}</div>
              <svg
                class="h-6 w-6 text-red-500"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                style={{
                  color: "white",
                }}
              >
                {" "}
                <circle cx="12" cy="8" r="7" />{" "}
                <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88" />
              </svg>
            </div>
          </div>

          <div className="absolute bottom-10 right-5 mb-20">
            <div className="relative max-h-600 overflow-hidden">
              <div
                ref={commentsRef} // Reference to the comments container
                className="overflow-auto shadow-md dark:bg-gray-800 p-2 rounded-md"
                style={{ height: "200px" }} // Set the height to 200px
              >
                {comments.slice(-visibleComments).map((comment, index) => (
                  <div
                    key={index}
                    dir="rtl"
                    className="flex items-center gap-4 mt-4"
                  >
                    <img
                      className="w-11 h-11 rounded-full"
                      src={userProfileImg}
                      alt="User Profile"
                    />
                    <div>
                      <div className="font-bold text-white dark:text-white">
                        {comment.user.name}
                      </div>
                      <div className="text-sm text-white dark:text-gray-400">
                        {comment.comment}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div dir="rtl" className="absolute bottom-5 right-5 mt-4">
            <div className="flex items-center">
              <img
                className="w-11 h-11 rounded-full"
                src={userProfileImg}
                alt="User Profile"
              />
              <div className="relative mr-2">
                <form onSubmit={(e) => handleComment(e)}>
                  <input
                    type="text"
                    className="border rounded-full pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="اكتب تعليق"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                  <span className="absolute left-3 top-1/2 transform -translate-y-1/2">
                    <svg
                      class="h-8 w-8 text-red-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{
                        color: "#48b3bd",
                      }}
                      onClick={handleComment}
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
                      />
                    </svg>
                  </span>
                </form>
              </div>
            </div>
          </div>

          {/* {isBroadcaster === "true" ? (
            <div
              data-dial-init
              className="absolute left-6 bottom-20 group"
              style={{ marginBottom: "100px" }}
            >
              
            </div>
          ) : null} */}
          {isBroadcaster === "true" ? (
            <div
              data-dial-init
              className="absolute  group rounded-full p-2"
              style={{
                left: "30px",
                bottom: "170px",
                background: "white",
              }}
              onClick={() => {
                console.log("Clicked");
                handleMuteToggle();
              }}
            >
              {isMuted ? (
                <FaMicrophoneSlash width={50} />
              ) : (
                <FaMicrophone width={50} />
              )}
            </div>
          ) : null}

          {isBroadcaster !== "true" ? (
            <div
              data-dial-init
              className="absolute left-6 bottom-20 group mb-3"
            >
              <button
                type="button"
                data-dial-toggle="speed-dial-menu-default"
                aria-controls="speed-dial-menu-default"
                className="flex flex-col items-center justify-center text-white bg-yellow-700 rounded-full w-14 h-14 hover:bg-yellow-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
                style={{
                  backgroundColor: "#48B3BD",
                  padding: "8px",
                }}
                onClick={handleShowBid}
              >
                <svg
                  className="h-5 w-5 text-red-500"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  style={{
                    color: "white",
                  }}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div className="text-sm mt-0">مزاودة</div>
              </button>
            </div>
          ) : (
            <div
              data-dial-init
              className="absolute left-6 bottom-20 group mb-3"
            >
              <button
                type="button"
                data-dial-toggle="speed-dial-menu-default"
                aria-controls="speed-dial-menu-default"
                className="flex flex-col items-center justify-center text-white bg-yellow-700 rounded-full w-14 h-14 hover:bg-yellow-800 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
                onClick={handleShowAwarding}
              >
                <img
                  src={vectoryVector}
                  alt="vectory"
                  style={{ margin: "0 auto" }}
                />
                <div className="text-sm mt-0">ترسية</div>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LiveAuction;
