import React, { useContext, useEffect, useState } from "react";
import auctionsIcon from "../../assets/auctionsIcon.png";
import "./auctions.css";
import searchIcon from "../../assets/Search.png";
import AuctionsVector from "../../assets/auctionsVector.png";
import walletVector from "../../assets/WalletVector.png";
import transactionWallet from "../../assets/transactionsVector.png";
import chatVector from "../../assets/chatVector.png";
import requestVector from "../../assets/request.png";
import Slider from "@mui/material/Slider";
import AuctionCard from "../../components/auctionCard/auctionCard";
import axios from "axios";
import { toast } from "react-toastify";
import { UserContext } from "../../context/userContext";
import { useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import WinningCard from "../../components/winningCard/winningCard";
import AuctionDetails from "../../components/auctionDetails/auctionDetails";

const Auctions = () => {
  const [auctions, setAuctions] = useState([]);
  const [categories, setCategories] = useState([]);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [socket, setSocket] = useState(null);
  const [showAuctions, setShowAuctions] = useState(true);
  const [showWinnings, setShowWinnings] = useState(false);
  const [winnings, setWinnings] = useState({});
  const [showAuctionDetails, setShowAuctionDetails] = useState(false);
  const [selectedAuction, setSelectedAuction] = useState(null);
  const location = useLocation();

  async function handleShowAllAuctions() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "auctions/get-all-auctions"
      );

      if (response.status === 200 || response.status === 201) {
        setShowWinnings(false);
        setShowAuctions(true);
        setAuctions(response.data.data);
      }
    } catch (error) {
      toast.error(error.message);
      toast.error(error.response.data.message);
    }
  }

  async function handleShowCategoryAuctions(id) {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "auctions/get-auctions-by-category",
        {
          params: {
            category_id: +id,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setShowWinnings(false);
        setShowAuctions(true);
        setAuctions(response.data.data);
      }
    } catch (error) {
      toast.error(error.message);
      toast.error(error.response.data.message);
    }
  }

  async function handleMyAuctions() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "auctions/get-user-auctions",
        {
          params: {
            user_id: +user.id,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setShowAuctions(true);
        setShowWinnings(false);
        setAuctions(response.data.data);
      }
    } catch (error) {
      toast.error(error.message);
      toast.error(error.response.data.message);
    }
  }

  async function handleWinnings() {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + "auctions/get-winnings",
        {
          params: {
            user_id: +user.id,
          },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setShowAuctions(false);
        setShowWinnings(true);
        setWinnings(response.data.data);
      }
    } catch (error) {
      toast.error(error.message);
      toast.error(error.response.data.message);
    }
  }

  async function handleStartLive(id, startDate, endDate) {
    if (socket) {
      socket.emit("startLiveAuction", { auctionId: id, userId: user.id });
      navigate(`/liveAuction/${id}`);
    }
  }

  async function handleJoinLive(id) {
    navigate(`/liveAuction/${id}`);
  }

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "categories/get-all-categories")
      .then((res) => setCategories(res.data.data));
    axios
      .get(process.env.REACT_APP_BASE_URL + "auctions/get-all-auctions")
      .then((response) => {
        setAuctions(response.data.data);
        console.log(response.data.data);
      })
      .catch((error) => toast.error(error.message));

    // Initialize socket connection
    const socketConnection = io(process.env.REACT_APP_SOCKET_URL, {
      transports: ["websocket"], // Specify transports if needed
    });

    setSocket(socketConnection);

    // Clean up on unmount
    return () => {
      socketConnection.disconnect();
    };
  }, []);

//  useEffect(() => {
//   // Check if the page has been reloaded before
//   const reloadedFlag = sessionStorage.getItem('reloaded');

//   // If the page has not been reloaded before, reload it and set the flag
//   if (!reloadedFlag) {
//     sessionStorage.setItem('reloaded', 'true');
//     window.location.reload();
//   }

//   return () => {
//     // After the reload, remove the flag to avoid any future reloads
//     sessionStorage.removeItem('reloaded');
//   };
// }, [location.pathname]); // Only re-run the effect if the pathname changes

  return (
    <div dir="rtl" className="py-16 mt-20 mb-15 auctions-page">
      <AuctionDetails
        setOpen={setShowAuctionDetails}
        open={showAuctionDetails}
        auction={selectedAuction}
      />
      <div class="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m8.25 4.5 7.5 7.5-7.5 7.5"
          />
        </svg>
        <div className="flex">
          <img src={auctionsIcon} class="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
          <div
            class="self-center text-xl font-bold whitespace-nowrap dark:text-white"
            style={{ color: "#42261B" }}
          >
            المزادات المتاحة
          </div>
        </div>
      </div>

      <div className="flex items-center flex-wrap mt-6 buttons-container mr-10">
        <button
          type="button"
          class="focus:outline-none text-white  font-bold rounded-lg text-sm px-5 py-2.5 mb-2  auction-button"
          onClick={handleShowAllAuctions}
        >
          كل المزادات
        </button>
        {categories.map((category) => (
          <button
            type="button"
            class="focus:outline-none text-white  font-bold rounded-lg text-sm px-5 py-2.5 mb-2  auction-button"
            onClick={() => handleShowCategoryAuctions(category.id)}
          >
            مزادات {category.name}
          </button>
        ))}
        {/* <button
          type="button"
          class="focus:outline-none text-white  font-bold rounded-lg text-sm px-5 py-2.5 mb-2  auction-button"
          onClick={handleMyAuctions}
        >
          مزاداتى
        </button> */}
        <button
          type="button"
          class="focus:outline-none text-white  font-bold rounded-lg text-sm px-5 py-2.5 mb-2  auction-button"
          onClick={handleWinnings}
        >
          مزادات ربحت بها
        </button>
      </div>

      <div class="flex flex-col md:flex-row mt-5">
        <div class="w-2/10 hidden md:block flex-row">
          <div class="relative">
            <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
              <img src={searchIcon} alt="Search Icon" class="w-4 h-4" />
            </div>
            <input
              type="search"
              id="default-search"
              class="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="ابحث"
              required
            />
          </div>

          <div className="auctions-options mt-4 p-4 flex-row">
            <div
              className="flex items-center gap-4"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/requestLiveAuction");
              }}
            >
              <img className="w-4 h-4" src={requestVector} alt="Auctions" />
              <div className="font-bold dark:text-white">
                تقديم طلب مزاد مباشر
              </div>
            </div>
            <div
              className="flex items-center gap-4 mt-4"
              style={{ cursor: "pointer" }}
              onClick={handleMyAuctions}
            >
              <img className="w-4 h-4" src={AuctionsVector} alt="Auctions" />
              <div className="font-bold dark:text-white">مزاواداتى</div>
            </div>

            <div
              className="flex items-center gap-4 mt-4"
              style={{ cursor: "pointer" }}
            >
              <img className="w-4 h-4" src={walletVector} alt="Wallet" />
              <div className="font-bold dark:text-white">مزاوادات ربحت بها</div>
            </div>

            <div className="flex items-center gap-4 mt-4">
              <img
                className="w-4 h-4"
                src={transactionWallet}
                alt="Transaction"
              />
              <div className="font-bold dark:text-white">التحويلات</div>
            </div>

            <div className="flex items-center gap-4 mt-4">
              <img className="w-4 h-4" src={chatVector} alt="Chat" />
              <div className="font-bold dark:text-white">الشات</div>
            </div>
          </div>

          <div className="auctions-options mt-4 p-4 flex-row">
            <div className="flex items-center me-4">
              <input
                // checked={isChecked}
                id="yellow-checkbox"
                type="checkbox"
                value=""
                // onChange={handleChange}
                className="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="yellow-checkbox"
                className="ms-2 text-lg font-semibold text-gray-900 dark:text-gray-300"
              >
                مزاوادات مباشرة
              </label>
            </div>
            <div className="flex items-center me-4 mt-4">
              <input
                // checked={isChecked}
                id="yellow-checkbox"
                type="checkbox"
                value=""
                // onChange={handleChange}
                className="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="yellow-checkbox"
                className="ms-2 text-lg font-semibold text-gray-900 dark:text-gray-300"
              >
                مزاوادات مفتوحة
              </label>
            </div>
            <div className="flex items-center me-4 mt-4">
              <input
                // checked={isChecked}
                id="yellow-checkbox"
                type="checkbox"
                value=""
                // onChange={handleChange}
                className="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="yellow-checkbox"
                className="ms-2 text-lg font-semibold text-gray-900 dark:text-gray-300"
              >
                مزاوادات فردية
              </label>
            </div>
            <div className="flex items-center me-4 mt-4">
              <input
                // checked={isChecked}
                id="yellow-checkbox"
                type="checkbox"
                value=""
                // onChange={handleChange}
                className="w-4 h-4 text-yellow-400 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="yellow-checkbox"
                className="ms-2 text-lg font-semibold text-gray-900 dark:text-gray-300"
              >
                مزاوادات منتهية
              </label>
            </div>

            <div className="mt-5">
              <select
                id="small"
                class="block w-full p-2 mb-6 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              >
                <option selected>المنطقة</option>
                <option value="US">United States</option>
                <option value="CA">Canada</option>
                <option value="FR">France</option>
                <option value="DE">Germany</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="small-range"
                className="block text-sm font-medium text-gray-900 dark:text-white"
              >
                المسافة (كم)
              </label>
              <Slider
                size="small"
                defaultValue={70}
                aria-label="Small"
                valueLabelDisplay="auto"
                sx={{
                  color: "#48B3BD", // Track and thumb color
                  "& .MuiSlider-thumb": {
                    backgroundColor: "#48B3BD", // Thumb color
                  },
                  "& .MuiSlider-rail": {
                    color: "gray", // Rail color
                  },
                }}
              />
            </div>

            <div>
              <label
                htmlFor="small-range"
                className="block text-sm font-medium text-gray-900 dark:text-white"
              >
                مبلغ المزاودة الأولى
              </label>
              <Slider
                size="small"
                defaultValue={70}
                aria-label="Small"
                valueLabelDisplay="auto"
                sx={{
                  color: "#48B3BD", // Track and thumb color
                  "& .MuiSlider-thumb": {
                    backgroundColor: "#48B3BD", // Thumb color
                  },
                  "& .MuiSlider-rail": {
                    color: "gray", // Rail color
                  },
                }}
              />
            </div>

            <div>
              <label
                htmlFor="small-range"
                className="block text-sm font-medium text-gray-900 dark:text-white"
              >
                مساحة السلعة
              </label>
              <Slider
                size="small"
                defaultValue={70}
                aria-label="Small"
                valueLabelDisplay="auto"
                sx={{
                  color: "#48B3BD", // Track and thumb color
                  "& .MuiSlider-thumb": {
                    backgroundColor: "#48B3BD", // Thumb color
                  },
                  "& .MuiSlider-rail": {
                    color: "gray", // Rail color
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div class="w-7/10 md:w-7/10  mx-auto flex-row">
          {showAuctions
            ? auctions.map((auction) => (
                <AuctionCard
                  key={auction.id}
                  id={auction.id}
                  startDate={auction.startDate}
                  endDate={auction.expiryDate}
                  title={auction.title}
                  imgURL={auction.image_url}
                  owner={auction.user.name}
                  products={auction.auction_products}
                  price={auction.actualPrice}
                  quantity={auction.quantity}
                  subCategories={auction.Auction_subCategories_relations}
                  user={user}
                  creator_id={auction.user_id}
                  handleStartLive={handleStartLive}
                  hanldeJoinLive={handleJoinLive}
                  handleShowAuctionDetails={() => {
                    setShowAuctionDetails(true);
                    setSelectedAuction(auction);
                  }}
                />
              ))
            : null}

          {showWinnings
            ? Object.keys(winnings).map((winning) => (
                <WinningCard
                  winnings={winnings[winning]}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default Auctions;
