import React, { useEffect, useState } from "react";
import auctionImg from "../../assets/AuctionImg.jpeg";
import { toast } from "react-toastify";

const moment = require("moment");

const AuctionCard = ({
  id,
  title,
  imgURL,
  startDate,
  endDate,
  quantity,
  price,
  owner,
  products,
  subCategories = [],
  user,
  creator_id,
  handleStartLive,
  hanldeJoinLive,
  handleShowAuctionDetails
}) => {
  const [targetDate] = useState(new Date(startDate.replace("Z", "")));
  const [remainingTime, setRemainingTime] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [endDate1] = useState(new Date(endDate.replace("Z","")));
  const [nowDate] = useState(new Date())
  const [differenceDate] = useState(endDate1 - nowDate)

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();

      const difference = targetDate - now;

      if (difference > 0) {
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);

        // Format to HH:mm
        setRemainingTime(
          `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`
        );
      } else {
        // Time has passed
        setRemainingTime("00:00");
        setIsButtonDisabled(false)
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [targetDate]);

  return (
    <div
      className="mt-4 flex flex-col md:flex-row items-center bg-white border border-gray-200 rounded-lg shadow  hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700"
      style={{
        width: "100%",
      }}
    >
      <img
        className="object-cover w-full rounded-t-lg  md:h-auto md:w-48 md:rounded-none md:rounded-s-lg"
        src={process.env.REACT_APP_BASE_URL + imgURL}
        alt="Technology acquisitions 2021"
      />
      <div
        className="flex flex-col justify-between p-4 leading-normal"
        style={{ width: "100%" }}
      >
        <div className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
          {title}
        </div>
        <div className="flex flex-wrap">
          {subCategories.map((subCategory) => (
            <span
              class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
              style={{
                backgroundColor: "#F2F2F2",
                color: "#939597",
              }}
            >
              {subCategory.name} {subCategory.children[0].name}
            </span>
          ))}

          <span
            class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-2"
            style={{
              backgroundColor: "#F2F2F2",
              color: "#939597",
            }}
          >
            صاحب المزاد : {owner}
          </span>
          <span
            class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-2"
            style={{
              backgroundColor: "#F2F2F2",
              color: "#939597",
            }}
          >
            عدد السلع : {products.length}
          </span>
        </div>

        <div className="mt-4 flex flex-wrap">
          <span
            class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-2 cursor-pointer"
            style={{
              backgroundColor: "black",
              color: "white",
            }}
            onClick={handleShowAuctionDetails}
          >
            مشاهدة تفاصيل المزاد
          </span>

          <span
            class="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-2"
            style={{
              backgroundColor: "red",
              color: "white",
            }}
          >
            {
              differenceDate > 0 ? `الوقت المتبقى ==> ${remainingTime}`: "لقد تم انتهاء المزاد"
            }
          </span>
        </div>

        <div className="mt-4">
          {user.id === creator_id ? (
            <button
              className={`bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button ${isButtonDisabled || differenceDate < 0 ? "cursor-not-allowed" : ""} `}
              onClick={() => {
                const startDate1 = new Date(startDate.replace("Z", ""));
                const now = new Date(
                  moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
                );

                if (now >= startDate1) {
                  handleStartLive(id, startDate, endDate);
                } else {
                  toast.error(
                    `ميعاد بدأ المزاد \n${startDate.replace("Z", "")}`
                  );
                }
              }}
              style={{
                backgroundColor: "green",
                width: "100%",
              }}
              disabled={isButtonDisabled || differenceDate < 0}
            >
              ابدأ اللايف
            </button>
          ) : (
            <button
              className={`bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button ${isButtonDisabled || differenceDate < 0 ? "cursor-not-allowed" : ""}  `}
              onClick={() => {
                const startDate1 = new Date(startDate.replace("Z", ""));
                const now = new Date(
                  moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")
                );

                if (now >= startDate1) {
                  handleStartLive(id, startDate, endDate);
                } else {
                  toast.error(
                    `ميعاد بدأ المزاد \n${startDate.replace("Z", "")}`
                  );
                }
              }}
              style={{
                backgroundColor: "green",
                width: "100%",
              }}
              disabled={isButtonDisabled || differenceDate < 0}
            >
              مشاهدة اللايف
            </button>
            // <span
            //   class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
            //   style={{
            //     backgroundColor: "green",
            //     color: "white",
            //     cursor: "pointer",
            //   }}
            //   onClick={() => handleStartLive(id)}
            // >
            //   ابدأ اللايف
            // </span>
            // <span
            //   class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300"
            //   style={{
            //     backgroundColor: "green",
            //     color: "white",
            //     cursor: "pointer",
            //   }}
            //   onClick={() => hanldeJoinLive(id)}
            // >
            //   مشاهدة اللايف
            // </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuctionCard;
