import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UserContext } from "../../context/userContext";
import { toast } from "react-toastify";

const moment = require("moment");

const LiveAuctionRequest = () => {
  const { user } = useContext(UserContext);

  const [request, setRequest] = useState({
    title: "",
    user_id: user.id,
    category_id: 0,
    expiry_date: "",
    startDate: "",
    options: [],
  });

  const [categories, setCategories] = useState([]);
  const [imageFile, setImageFile] = useState(null);

  const handleSave = async (e) => {
    const formData = new FormData();
    formData.append("title", request.title);
    formData.append("user_id", request.user_id);
    formData.append("category_id", request.category_id);
    formData.append("expiryDate", request.expiry_date);
    formData.append("startDate", request.startDate);
    if (imageFile) {
      formData.append("image_url", imageFile);
    }
    formData.append("products", JSON.stringify(request.options));

    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL + "live-auction-requests/add-live-auction-request",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("تم ارسال طلبك بنجاح");
      setRequest({
        title: "",
        user_id: user.id,
        category_id: 0,
        expiry_date: "",
        startDate: "",
        options: [],
      });
      setImageFile(null);
    } catch (error) {
      toast.error("Error submitting the form:", error);
    }
  };

  const handleAddOption = () => {
    setRequest((prevRequest) => ({
      ...prevRequest,
      options: [
        ...prevRequest.options,
        { product: "", bidPrice: "", minBidPrice: "", actualPrice: "" },
      ],
    }));
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...request.options];
    updatedOptions[index][field] = value;
    setRequest({ ...request, options: updatedOptions });
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = request.options.filter((_, i) => i !== index);
    setRequest({ ...request, options: updatedOptions });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
    }
  };

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "categories/get-all-categories")
      .then((res) => setCategories(res.data.data));
  }, []);

  return (
    <div className="py-8 md:py-12 lg:py-16 mt-12 mb-10" dir="rtl">
      <div
        className="bg-white rounded-lg shadow-lg overflow-hidden mx-auto w-11/12 max-w-md md:max-w-2xl lg:max-w-6xl px-4 md:px-8 lg:px-12"
        style={{
          paddingTop: "2rem",
        }}
      >
        <div className="mt-4 flex space-x-4 space-x-reverse">
          <div className="flex-1">
            <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
              اسم المزاد
            </label>
            <input
              type="text"
              value={request.title}
              onChange={(e) =>
                setRequest({
                  ...request,
                  title: e.target.value,
                })
              }
              className="bg-white-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none text-right"
            />
          </div>
        </div>
        <div className="mt-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
            التصنيف
          </label>
          <select
            onChange={(e) => {
              setRequest({
                ...request,
                category_id: +e.target.value,
              });
            }}
            className="bg-white text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-full appearance-none text-right"
          >
            <option value={0}>...</option>
            {categories.map((category) => (
              <option value={category.id}>{category.name}</option>
            ))}
          </select>
        </div>

        <div className="mt-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
            تاريخ ووقت بداية المزاد
          </label>
          <input
            type="datetime-local"
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={request.startDate}
            onChange={(e) =>
              setRequest({
                ...request,
                startDate: e.target.value,
              })
            }
            required
          />
        </div>

        <div className="mt-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
            تاريخ ووقت نهاية المزاد
          </label>
          <input
            type="datetime-local"
            className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={request.expiry_date}
            onChange={(e) =>
              setRequest({
                ...request,
                expiry_date: e.target.value,
              })
            }
            required
          />
        </div>

        <div className="mt-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-right">
            صورة المزاد
          </label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="w-full p-2 border rounded-md focus:outline-none"
          />
          {imageFile && (
            <div className="mt-4 text-right">
              <p className="text-sm text-gray-600">تم رفع الصورة بنجاح:</p>
              <img
                src={URL.createObjectURL(imageFile)}
                alt="Auction Preview"
                className="w-full max-w-sm rounded-md shadow-md mt-2"
              />
            </div>
          )}
        </div>

        <div className="mt-6">
          <h2 className="text-lg font-bold mb-4 text-right">سلع المزاد</h2>
          {request.options.map((option, index) => (
            <div
              key={index}
              className="border p-4 mb-4 rounded-lg shadow-md bg-gray-50"
            >
              <div className="flex items-center space-x-4 space-x-reverse">
                <div className="flex-1">
                  <label className="block text-sm font-bold mb-2 text-right">
                    اسم السلعة
                  </label>
                  <input
                    type="text"
                    value={option.product}
                    onChange={(e) =>
                      handleOptionChange(index, "product", e.target.value)
                    }
                    className="w-full p-2 border rounded-md focus:outline-none"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-bold mb-2 text-right">
                    حد السوم
                  </label>
                  <input
                    type="number"
                    value={option.actualPrice}
                    onChange={(e) =>
                      handleOptionChange(index, "actualPrice", e.target.value)
                    }
                    className="w-full p-2 border rounded-md focus:outline-none"
                  />
                </div>
              </div>
              <div className="flex items-center space-x-4 space-x-reverse mt-4">
                <div className="flex-1">
                  <label className="block text-sm font-bold mb-2 text-right">
                    فرق السوم
                  </label>
                  <input
                    type="number"
                    value={option.bidPrice}
                    onChange={(e) =>
                      handleOptionChange(index, "bidPrice", e.target.value)
                    }
                    className="w-full p-2 border rounded-md focus:outline-none"
                  />
                </div>
                <div className="flex-1">
                  <label className="block text-sm font-bold mb-2 text-right">
                    فتح الباب
                  </label>
                  <input
                    type="number"
                    value={option.minBidPrice}
                    onChange={(e) =>
                      handleOptionChange(index, "minBidPrice", e.target.value)
                    }
                    className="w-full p-2 border rounded-md focus:outline-none"
                  />
                </div>
              </div>
              <button
                type="button"
                className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700"
                onClick={() => handleRemoveOption(index)}
              >
                حذف السلعة
              </button>
            </div>
          ))}
          <button
            type="button"
            className="mt-4 bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
            onClick={handleAddOption}
          >
            إضافة سلعة
          </button>
        </div>

        <div className="mt-8 mb-8">
          <button
            className="bg-gray-700 text-white font-bold py-3 px-4 w-full rounded signIn-button"
            style={{ backgroundColor: "#48B3BD" }}
            onClick={(e) => handleSave(e)}
          >
            تقديم الطلب
          </button>
        </div>
      </div>
    </div>
  );
};

export default LiveAuctionRequest;
