import React from "react";
import barakaFooter from "../../assets/BarakaFooter.png";
import facebook from "../../assets/facebookFooter.png";
import twitter from "../../assets/twitterFooter.png";
import linkedIn from "../../assets/linkedinFooter.png";

const Footer = () => {
  return (
    <footer
      dir="rtl"
      className="bg-white dark:bg-gray-900"
      style={{ backgroundColor: "#48B3BD" }}
    >
      <div className="mx-auto w-full p-6 lg:p-40 py-6 lg:py-10">
        <div className="md:flex md:justify-between">
          {/* Footer Logo */}
          <div className="mb-6 md:mb-0">
            <a href="/auctions" className="flex items-center">
              <img
                src={barakaFooter}
                className="h-30 me-3"
                alt="FlowBite Logo"
              />
            </a>
          </div>

          {/* First Column */}
          <div className="mb-6">
            <h2 className="mb-6 text-lg font-bold text-white uppercase dark:text-white">
              حول بركة
            </h2>
            <ul className="text-white dark:text-gray-400 font-medium">
              <li className="mb-2">
                <a href="/aboutUs" className="hover:underline">
                  من نحن
                </a>
              </li>
              {/* <li className="mb-2">
                <a href="https://tailwindcss.com/" className="hover:underline">
                  مدونة
                </a>
              </li> */}
              <li>
                <a
                  href="https://tailwindcss.com/"
                  className="hover:underline"
                  dir="ltr"
                >
                  +966 53 354 6607 : اتصل بنا
                </a>
              </li>
            </ul>
          </div>

          {/* Second Column */}
          {/* <div className="mb-6">
            <h2 className="mb-6 text-lg font-bold text-white uppercase dark:text-white">
              الأحكام العامة
            </h2>
            <ul className="text-white dark:text-gray-400 font-medium">
              <li className="mb-2">
                <a
                  href="https://github.com/themesberg/flowbite"
                  className="hover:underline"
                >
                  خيارات الدفع والتوصيل
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="https://discord.gg/4eeurUVvTy"
                  className="hover:underline"
                >
                  سياسة الاستبدال والاسترجاع
                </a>
              </li>
              <li className="mb-2">
                <a
                  href="https://discord.gg/4eeurUVvTy"
                  className="hover:underline"
                >
                  سياسة الاستخدام والخصوصية
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/4eeurUVvTy"
                  className="hover:underline"
                >
                  الأسئلة الشائعة
                </a>
              </li>
            </ul>
          </div> */}

          {/* Third Column */}
          {/* <div>
            <h2 className="mb-6 text-lg font-bold text-white uppercase dark:text-white">
              النشرة الإخبارية
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              <li className="mb-4">
                <input
                  className="bg-gray-200 text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 block w-100 appearance-none text-right"
                  type="text"
                  placeholder="بريدك الالكترونى"
                />
              </li>
              <li>
                <button
                  className="bg-gray-700 text-white font-bold py-3 px-4 w-40 rounded text-right"
                  style={{ backgroundColor: "#FAC440" }}
                >
                  الإشتراك
                </button>
              </li>
            </ul>
          </div> */}
        </div>

        {/* Divider */}
        <div className="my-6 sm:mx-auto lg:my-8" />

        {/* Bottom Section */}
        <div className="sm:flex sm:items-center sm:justify-between flex-col-reverse sm:flex-row">
          <div className="flex-col text-sm">
            <p className="text-white">المملكة العربية السعودية</p>
            {/* <p className="text-white">رقم السجل التجاري: 1010816446</p> */}
          </div>


          {/* <div
            dir="ltr"
            className="flex mt-4 sm:justify-center sm:mt-0"
            style={{
              justifyContent: "center",
            }}
          >

            <a
              href="https://www.instagram.com/nawadir_sa?igsh=MTQyNHNlZmtreGlwMA=="
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white flex justify-center ms-9"
            >
              <span
                class="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-[#fff]"
                style={{ color: "white" }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                </svg>
              </span>
              <span className="text-white ml-2">Instgram</span>
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
