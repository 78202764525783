import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Adjusted for responsiveness
  maxWidth: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: { xs: 2, sm: 4 }, // Padding adjusts for smaller screens
};

const AuctionDetails = ({ auction, open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            maxHeight: "70vh",
            overflowY: "auto",
            backgroundColor: "white",
          }}
        >
          <Typography
            style={{ textAlign: "center" }}
            variant="h5" // Adjusted for mobile-friendly size
            gutterBottom
          >
            تفاصيل المزاد
          </Typography>
          <Paper elevation={3} sx={{ padding: { xs: 2, sm: 3 }, mt: 2 }}>
            {/* Auction Image */}
            <Box sx={{ textAlign: "center", mb: 3 }}>
              {auction?.image_url ? (
                <img
                  src={process.env.REACT_APP_IMAGE_URL + auction.image_url}
                  alt={auction.title || "صورة المزاد"}
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <Typography variant="body1" color="textSecondary">
                  لا توجد صورة متوفرة
                </Typography>
              )}
            </Box>

            {/* Auction Details */}
            <Typography variant="subtitle1">اسم المزاد</Typography>
            <Typography>{auction?.title || "غير متوفر"}</Typography>

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              صاحب المزاد
            </Typography>
            <Typography>{auction?.user?.name || "غير متوفر"}</Typography>

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              بداية المزاد
            </Typography>
            <Typography>{auction?.startDate || "غير متوفر"}</Typography>

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              نهاية المزاد
            </Typography>
            <Typography>{auction?.expiryDate || "غير متوفر"}</Typography>

            <Typography variant="subtitle1" sx={{ mt: 2 }}>
              الحالة
            </Typography>
            <Typography>
              {auction?.confirmed ? "تم التفعيل" : "لم يتم التفعيل"}
            </Typography>

            {/* Options Table */}
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
              سلع المزاد
            </Typography>
            {auction?.auction_products?.length > 0 ? (
              <TableContainer
                component={Paper}
                sx={{
                  mt: 2,
                  maxWidth: "100%",
                  overflowX: "auto", // Handle horizontal scroll on small screens
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>المنتج</TableCell>
                      <TableCell>حد السوم</TableCell>
                      <TableCell>فرق السوم</TableCell>
                      <TableCell>فتح الباب</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {auction?.auction_products.map((option, index) => (
                      <TableRow key={index}>
                        <TableCell>{option.product || "غير متوفر"}</TableCell>
                        <TableCell>
                          {option.actualPrice || "غير متوفر"}
                        </TableCell>
                        <TableCell>
                          {option.bidPrice || "غير متوفر"}
                        </TableCell>
                        <TableCell>
                          {option.minBidPrice || "غير متوفر"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>لا توجد خيارات متوفرة</Typography>
            )}
          </Paper>
        </div>
      </Box>
    </Modal>
  );
};

export default AuctionDetails;
