import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RequestDetails = ({ request, open, setOpen }) => {
  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box style={style}>
        <div
          style={{
            maxHeight: "70vh",
            overflowY: "auto",
            backgroundColor: "white",
          }}
        >
          <Typography variant="h4" gutterBottom>
            تفاصيل الطلب
          </Typography>
          <Paper elevation={3} sx={{ padding: 3, mt: 2 }}>
            {/* Auction Image */}
            <Box sx={{ textAlign: "center", mb: 3 }}>
              {request?.image_url ? (
                <img
                  src={process.env.REACT_APP_IMAGE_URL + request.image_url}
                  alt={request.title || "صورة المزاد"}
                  style={{
                    width: "100%",
                    maxWidth: "400px",
                    borderRadius: "8px",
                  }}
                />
              ) : (
                <Typography variant="body1" color="textSecondary">
                  لا توجد صورة متوفرة
                </Typography>
              )}
            </Box>

            {/* Auction Details */}
            <Typography variant="h6">اسم المزاد</Typography>
            <Typography>{request?.title || "غير متوفر"}</Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
              صاحب المزاد
            </Typography>
            <Typography>{request?.user?.name || "غير متوفر"}</Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
              بداية المزاد
            </Typography>
            <Typography>{request?.startDate || "غير متوفر"}</Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
              نهاية المزاد
            </Typography>
            <Typography>{request?.expiryDate || "غير متوفر"}</Typography>

            <Typography variant="h6" sx={{ mt: 2 }}>
              الحالة
            </Typography>
            <Typography>
              {request?.confirmed ? "تم التفعيل" : "لم يتم التفعيل"}
            </Typography>

            {/* Options Table */}
            <Typography variant="h6" sx={{ mt: 3 }}>
              خيارات المزاد
            </Typography>
            {request?.request_products?.length > 0 ? (
              <TableContainer component={Paper} sx={{ mt: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>المنتج</TableCell>
                      <TableCell>حد السوم</TableCell>
                      <TableCell>فرق السوم</TableCell>
                      <TableCell>فتح الباب</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {request?.request_products.map((option, index) => (
                      <TableRow key={index}>
                        <TableCell>{option.product || "غير متوفر"}</TableCell>
                        <TableCell>
                          {option.actualPrice || "غير متوفر"}
                        </TableCell>
                        <TableCell>{option.bidPrice || "غير متوفر"}</TableCell>
                        <TableCell>
                          {option.minBidPrice || "غير متوفر"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>لا توجد خيارات متوفرة</Typography>
            )}
          </Paper>

          {/* Actions */}
          <Box sx={{ mt: 4, textAlign: "center" }}>
            {!request?.confirmed && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  axios
                    .patch(
                      process.env.REACT_APP_BASE_URL +
                        `live-auction-requests/confirm-request`,
                      null,
                      {
                        params: { request_id: request.id },
                      }
                    )
                    .then(() => toast.success("Request confirmed successfully"))
                    .catch((err) => toast.error(err.message))
                }
              >
                تأكيد الطلب
              </Button>
            )}
          </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default RequestDetails;
