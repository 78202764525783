import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Orders = () => {
  const [orders, setOrders] = useState([]);

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "cName",
      headerName: "اسم العميل",
      flex: 1,
    },
    {
      field: "cMobile",
      headerName: "رقم الهاتف",
      flex: 1,
    },
    {
      field: "sCity",
      headerName: "المدينة",
      flex: 1,
    },
    {
      field: "cAddress",
      headerName: "العنوان",
      flex: 1,
    },
    {
      field: "itemDesc",
      headerName: "وصف السلع",
      flex: 1,
    },
    {
      field: "PCs",
      headerName: "عدد القطع",
      flex: 1,
    },
    {
      field: "total",
      headerName: "اجمالى المبلغ",
      flex: 1,
    },
    {
      field: "codAmt",
      headerName: "طريقة الدفع",
      flex: 1,
      valueGetter: (params) => {
        // Check if the value of codAmt is greater than 0
        return params.value > 0 ? "كريديت كارد" : "عند الاستلام";
      },
    },
  ];

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "order/get-all-orders")
      .then((res) => {
        setOrders(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
        console.log(err);
      });
  }, []);
  return (
    <div>
      <Box sx={{ width: "100%", height: "calc(100vh - 100px)" }}>
        <div
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          عدد : {orders.length} اوردر
        </div>
        <DataGrid
          rows={orders}
          columns={columns}
          autoHeight={false}
          disableSelectionOnClick
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
            },
            "& .MuiDataGrid-cell": {
              wordWrap: "break-word",
              whiteSpace: "normal",
            },
            "& .MuiDataGrid-root": {
              overflowX: "auto", // Allow horizontal scrolling if needed on smaller screens
            },
          }}
        />
      </Box>
    </div>
  );
};

export default Orders;
