import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import RequestDetails from "../requestDetails/requestDetails";

const LiveAuctionRequests = () => {
  const [request, setRequest] = useState(null);
  const [requests, setRequests] = useState([]);
  const [flag, setFlag] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleDoubleClick = (e) => {
    setOpenModal(true);
  };

  const handleConfirmRequest = (id) => {
    axios
      .patch(
        process.env.REACT_APP_BASE_URL +
          `live-auction-requests/confirm-request`,
        null,
        {
          params: {
            request_id: +id,
          },
        }
      )
      .then(() => {
        toast.success("Request confirmed successfully");
        setFlag(!flag); // Refresh the list after confirmation
      })
      .catch((err) => toast.error(err.message));
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "title",
      headerName: "اسم المزاد",
      flex: 1,
    },
    {
      field: "user",
      headerName: "صاحب المزاد",
      width: 150,
      // Accessing the nested element
      valueGetter: (params) => {
        return params.name;
      },
    },
    {
      field: "category",
      headerName: "التصنيف",
      width: 150,
      // Accessing the nested element
      valueGetter: (params) => {
        return params.name;
      },
    },

    {
      field: "startDate",
      headerName: "بداية المزاد",
      flex: 1,
    },
    {
      field: "expiryDate",
      headerName: "نهاية المزاد",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "الإجراءات",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleConfirmRequest(params.row.id)}
          disabled={params.row.confirmed} // Disable if already confirmed
        >
          {params.row.confirmed ? "تم التفعيل" : "تفعيل"}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BASE_URL +
          "live-auction-requests/get-all-requests"
      )
      .then((res) => setRequests(res.data.data))
      .catch((err) => toast.error(err.message));
  }, [flag]);

  return (
    <div style={{ fontFamily: "Cairo" }}>
      <RequestDetails
        request={request}
        open={openModal}
        setOpen={setOpenModal}
      />
      <Box sx={{ width: "100%", height: "calc(100vh - 100px)" }}>
        <div
          style={{
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        >
          عدد : {requests.length} طلب
        </div>
        <DataGrid
          rows={requests}
          columns={columns}
          autoHeight={false}
          onRowClick={(e) => setRequest(e.row)}
          onRowDoubleClick={handleDoubleClick}
          disableSelectionOnClick
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          pageSizeOptions={[5]}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
            },
            "& .MuiDataGrid-cell": {
              wordWrap: "break-word",
              whiteSpace: "normal",
            },
            "& .MuiDataGrid-root": {
              overflowX: "auto", // Allow horizontal scrolling if needed on smaller screens
            },
          }}
        />
      </Box>
    </div>
  );
};

export default LiveAuctionRequests;
